import NavMenu from '../views/pages/Shared/NavMenu.vue';
import { RequestStore, RequestView } from '../views/pages/Request';
import oidc from '../vue-oidc-client';

export default [
    //aliases for legacy routing
    {
        path: '/',
        redirect: '/request/inbox',
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/feed/:method?/:id?',
        redirect: '/request/:method?/:id?',
        meta: {
            authName: oidc.authName,
        },
    },

    {
        name: 'request',
        path: '/request/:method(inbox|outbox|archive|store|view|\\d+)?/:id?',
        components: {
            menu: NavMenu,
            default: RequestStore,
            sharedPanel: RequestView,
        },
        meta: {
            authName: oidc.authName,
        },
    },
];
