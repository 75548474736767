<template>
    <Pane v-if="$route.params.serviceId" class="request_pane" max-size="60" min-size="45">
        <div class="request-create-view">
            <div class="load" v-if="isLoading">
                <template >
                    <VSpinner></VSpinner>
                </template>
            </div>

            <template v-else>
                <ValidationObserver class="request-form" v-slot="{ handleSubmit }">
                    <form class="active-form" @submit.prevent="handleSubmit(onSubmit)">
                        <ValidationProvider
                            class="active-form__field"
                            name="description"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <label class="active-form__label active-form__label_required" for="request-description"
                                >Описание</label
                            >

                            <VContenteditable
                                id="request-description"
                                name="description"
                                v-model="comment"
                            ></VContenteditable>

                            <span class="active-form__error">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                        <div class="scroll">
                            <FileViewer upload v-model="files" style="max-height: 250px"></FileViewer>
                        </div>
                        <div class="request-create-view__buttons">
                            <VButton type="button" class="button_green button_space-right" @click="toggleObserversLayer"
                                >Наблюдатели</VButton
                            >
                            <FileUploader class="button button_green" v-model="files">Загрузить</FileUploader>
                            <VButton class="button_green button_space-right" @click="toggleObserversLayer">Создать обращение</VButton>
                        </div>
                    </form>
                </ValidationObserver>
            </template>

            <div class="observers" v-if="showObserversLayer" :value="showObserversLayer">
                <div class="scroll">
                    <VRequestObservers v-model="observers"></VRequestObservers>
                </div>
                    <VButton @click="toggleObserversLayer" class="button_orange">Закрыть</VButton>
            </div>
        </div>
    </Pane>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import VContenteditable from '../../components/VContenteditable/VContenteditable.vue';
import { VRequestObservers } from '../../components';
import VButton from '@/views/components/VButton/VButton.vue';
import FileViewer from '@/views/components/FileViewer';
import FileUploader from '@/views/components/FileUploader';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Pane } from 'splitpanes';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import http from '../../../js/http';

@Component({
    components: {
        VContenteditable,
        VSpinner,
        VRequestObservers,
        VButton,
        Pane,

        FileViewer,
        FileUploader,

        ValidationObserver,
        ValidationProvider,
    },
})
class RequestCreateView extends Vue {
    files = [];
    comment = null;
    errorMessage = '';
    service = {};
    observers = [];
    isLoading = true;
    showObserversLayer = false;

    onSubmit() {
        this.isLoading = true;

        const files = this.getFileIds();

        const data = {
            files,
            comment: this.comment,
            observers: this.observers,
            serviceId: this.service.id,
        };

        http.post('/requests', data)
            .then(({ data: request }) => {
                this.$router.push({
                    name: 'request',
                    params: {
                        method: 'outbox',
                        id: request.id,
                    },
                });
            })
            .catch((error) => {
                this.errorMessage = this.$errorMessage(error);
            })
            .finally(() => {
                this.files = [];
                this.comment = null;
                this.errorMessage = '';
                this.isLoading = false;
            });
    }

    toggleObserversLayer() {
        this.showObserversLayer = !this.showObserversLayer;
    }

    getFileIds() {
        return this.files.filter((file) => file.id && file.id !== -1).map((file) => file.id);
    }

    get serviceId() {
        return this.$route.params.serviceId;
    }

    @Watch('serviceId')
    fetchService() {
        this.isLoading = true;
        http.get(`/services/${this.serviceId}?with=observers`)
            .then(({ data: service }) => {
                this.service = service;
                this.observers = service.observers.map((observer) => observer.id);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    created() {
        this.fetchService();
    }
}

export default RequestCreateView;
</script>
<style lang="scss">
.request_pane{
    z-index: 1000;
    box-shadow: 0 0 .6875rem 0 rgba(0, 0, 0, .07);
}
.request-form{
    width: 80%;
    display:flex;
}
.request-create-view{
    height: 100%;
    width: 100%;
    background-color: white;
    position: relative;
    top:0;
    right:0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__buttons{
       .button{
            margin: 10px 0;
            width:100%;
        }
    }
    .load{
        position: relative;
        top: 30%;
        left: 50%;
    }
    .observers{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 10px;
        .button_orange{
            margin-top: 20px;
        }
    }
    .scroll{
        overflow-y: auto;
    }
}
</style>
