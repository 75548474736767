import types from './RequestHistoryActionType';

export default class RequestHistoryActionTitle {
    static [types.CLOSED] = 'Закрыл(а) обращение';
    static [types.DENIED] = 'Отказал(а) в обращение';
    static [types.PAUSED] = 'Приостановил(а) обращение';
    static [types.REWORK] = 'Отправил(а) обращение на доработку';
    static [types.WORKUP] = 'Вернул(а) обращение в работу';
    static [types.FINISH] = 'Выполнил(а) обращение';
    static [types.CHANGE_EXECUTOR] = 'Изменил(а) исполнителя обращения на <span class="hl1">{_executor_}</span>';
    static [types.CHANGE_SECURITY_GROUP] = 'Изменил(а) группу исполнителей на <span class="hl1">{_executor_}</span>';
    static [types.CREATED] = 'Cоздал(а) обращение';
    static [types.COMMENTED] = 'Прокомментировал(а) обращение';
    static [types.SYSTEM_CLOSED] = 'Закрыл(а) обращение';
    static [types.SERVICE_CHANGED] = 'Изменил(а) категорию/сервис обращения';
    static [types.WATCHED] = 'Посмотрел(а) обращение';
}
