<template>
    <div>
        <VActiveFormItem :value="model.id" form="request.resource.form" name="id"></VActiveFormItem>
        <VActiveFormItem
            :type="Number"
            :value="categoryId"
            form="request.resource.form"
            name="categoryId"
        ></VActiveFormItem>
        <VActiveFormItem :value="model.title" form="request.resource.form" label="Имя:" name="title">
            <InputLight name="title" placeholder="Имя" type="text" v-model="model.title"></InputLight>
        </VActiveFormItem>
        <VActiveFormItem :value="model.subtitle" form="request.resource.form" label="Описание:" name="subtitle">
            <InputLight name="subtitle" placeholder="Описание" type="text" v-model="model.subtitle"></InputLight>
        </VActiveFormItem>
        <VActiveFormItem
            :type="Number"
            :value="model.hours"
            form="request.resource.form"
            label="Время на решение обращения:"
            name="hours"
        >
            <InputLight
                name="hours"
                placeholder="Время на решение обращения"
                type="number"
                v-model="model.hours"
            ></InputLight>
        </VActiveFormItem>
        <VActiveFormItem
            :value="model.observers"
            form="request.resource.form"
            label="Наблюдатели по умолчанию:"
            name="observers"
        >
            <VueSelect
                :options="observersOptions"
                :reduce="(observer) => observer.value"
                class="v-select-light"
                multiple
                placeholder="Наблюдатели по умолчанию"
                v-model="model.observers"
            ></VueSelect>
        </VActiveFormItem>
        <VActiveFormItem
            :type="Number"
            :value="model.securityGroupId"
            form="request.resource.form"
            label="Группа ответственных:"
            name="securityGroupId"
        >
            <VueSelect
                :options="securityGroupsOptions"
                :reduce="(securityGroup) => securityGroup.value"
                class="v-select-light"
                placeholder="Группа ответственных"
                v-model="model.securityGroupId"
            ></VueSelect>
        </VActiveFormItem>
        <VActiveFormItem
            :type="(executor) => (executor ? executor : null)"
            :value="model.executorId"
            form="request.resource.form"
            label="Исполнитель:"
            name="executorId"
            v-if="model.securityGroupId"
        >
            <VueSelect
                :options="executorsOptions"
                :reduce="(executor) => executor.value"
                class="v-select-light"
                placeholder="Исполнитель"
                v-model="model.executorId"
            ></VueSelect>
        </VActiveFormItem>
    </div>
</template>
<script>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import clone from 'lodash/clone';

import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import { InputLight, VActiveFormItem } from '../../../components';

const Workspace = namespace('workspace');

@Component({
    components: {
        VSpinner,
        InputLight,
        VActiveFormItem,
    },
})
class ServiceForm extends Vue {
    @Prop({
        type: Object,
        default: () => ({
            title: '',
            subtitle: '',
            hours: 4,
            observers: [],
            executorId: null,
            securityGroupId: 0,
        }),
    })
    service;
    @Prop({ type: [Number, String], default: 0 }) categoryId;

    @Workspace.State accounts;
    @Workspace.State securityGroups;

    instance = {};

    executorIdType(id) {
        return id ? id : null;
    }

    get observersOptions() {
        return this.accounts.map((account) => ({
            value: account.id,
            label: account.displayName,
        }));
    }

    get executorsOptions() {
        const securityGroup = this.securityGroups.find(
            (securityGroup) => securityGroup.id === this.model.securityGroupId,
        );

        return securityGroup
            ? securityGroup.accounts.map(({ id, displayName }) => ({
                  value: id,
                  label: displayName,
              }))
            : [];
    }

    get securityGroupsOptions() {
        return this.securityGroups.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }

    get model() {
        const observers = this.service.observers ? this.service.observers.map((observer) => observer.id) : [];

        return this.service.id ? (this.instance = clone({ ...this.service, observers })) : this.service;
    }

    @Watch('model.securityGroupId')
    securityGroupIdWatch() {
        this.model.executorId = null;
    }
}

export default ServiceForm;
</script>
