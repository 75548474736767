<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <p class="modal_accept__message">
           Обращение выполнено. Выберите действие: принять или вернуть в работу!
        </p>
        <div class="modal_accept__actions">
            <VButton type="button" class="active-form__action button_green-light" @click="backToWork">
                Вернуть в работу
            </VButton>

            <VButton type="submit" class="active-form__action button_green" @click="submit">
                Принять работу
            </VButton>
        </div>
    </VModalWrapper>
</template>
<script>
// Components
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import ErrorMessages from '../../js/enums/ErrorMessages';
import RequestStatusType from '@/js/enums/RequestStatusType';

@Component({
    components: {
        VButton,
        VModalWrapper,
    },
})
class VRequestAcceptWorkModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_ACCEPT_WORK })
    name;
    @Prop({ type: String, default: 'Принять работу' })
    defaultTitle;

    submit(){
        this.resolve({
            status: RequestStatusType.CLOSED,
        });
        this.hide();
    }

    backToWork(){
        this.resolve({
            status: RequestStatusType.WORKUP,
        });
        this.hide();
    }

    beforeOpen(event) {
        if (!event?.params?.request) {
            this.errorMessage = ErrorMessages.SOMETHING_WENT_WRONG;
        }

        this.request = event.params.request;
    }
}

export default VRequestAcceptWorkModal;
</script>
<style lang="scss">
.modal_accept{
    &__message {
        margin: 0 0 40px 0;
        font-size: 1.2rem;
        text-align: center;
    }
    &__actions{
        display: flex;
    }
}

</style>
