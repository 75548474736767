import { configure, extend, localize, setInteractionMode } from 'vee-validate';
import { confirmed, max, min, regex, required, email } from 'vee-validate/dist/rules';
import { customEager } from './vee-validate-modes';
import ru from 'vee-validate/dist/locale/ru.json';

extend('min', min);
extend('max', max);
extend('regex', regex);
extend('required', required);
extend('confirmed', confirmed);
extend('email', email);

localize({
    ru: {
        ...ru,
        names: {
            'service': 'сервис',
            'category': 'категория',
            'executor': 'Исполнитель',
            'security-group': 'группа ответственных',
            'comment': 'комментарии',
            'resume-date': 'дата возобновления',
            'description': 'описание',
            'solution': 'Решение',
        },
    },
});

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
    },
});

setInteractionMode('customEager', customEager);

localize('ru');
