<template>
    <RequestRow :class="['request__row_hover']">
        <RequestContent>
            <RequestCell :class="['request__cell_id', 'request__cell_border', 'request__cell_without-left-border']">
                <RequestContent :class="['request__content_center', 'request__content_hidden-ellipsis']"
                    >{{ request.id }}
                </RequestContent>

                <RequestOverlay
                    :class="[
                        'request__overlay_hover',
                        'request__overlay_first-cell',
                        'request__overlay_without-left-border',
                    ]"
                ></RequestOverlay>
            </RequestCell>

            <RequestCell :class="['request__cell_author', 'request__cell_border']" :title="request.author.displayName">
                <RequestContent :class="['request__content_center', 'request__content_hidden-ellipsis']">
                    <RequestUser :user="request.author" reduce></RequestUser>
                </RequestContent>

                <RequestOverlay :class="['request__overlay_hover']"></RequestOverlay>
            </RequestCell>

            <RequestCell :class="['request__cell_executor', 'request__cell_border']">
                <RequestContent :class="['request__content_center', 'request__content_hidden-ellipsis']">
                    <RequestUser :user="request.executor" reduce v-if="request.executor"></RequestUser>
                    <RequestUser :name="(securityGroup) => securityGroup.name" :user="request.securityGroup" v-else>
                    </RequestUser>
                </RequestContent>

                <RequestOverlay :class="['request__overlay_hover']"></RequestOverlay>
            </RequestCell>

            <RequestCell
                :class="['request__cell_date', 'request__cell_border']"
                :title="'до ' + $_toLocalDate(request.deadline)"
                :style="stateColor"
            >
                <RequestContent :class="['request__content_center', 'request__content_hidden-ellipsis']">
                    {{ terms(request) }}
                </RequestContent>
            </RequestCell>

            <RequestCell
                :class="['request__cell_status', 'request__cell_border', 'request__cell_without-right-border']"
            >
                <RequestContent :class="['request__content_hidden-ellipsis']">
                    <div :style="statusStyle" class="request__pill request__pill_status">
                        {{ statusTitle }}
                    </div>
                    <div v-if="isObserver" style="background-color: #0db5b5" class="request__pill request__pill_status">
                        Наблюдатель
                    </div>
                </RequestContent>

                <RequestOverlay
                    :class="[
                        'request__overlay_hover',
                        'request__overlay_last-cell',
                        'request__overlay_without-right-border',
                    ]"
                ></RequestOverlay>
            </RequestCell>
        </RequestContent>
    </RequestRow>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import last from 'lodash/last';
import titles from '@/js/enums/RequestStatusTitle';
import colors from '@/js/enums/RequestStatusColor';

import RequestRow from './RequestRow';
import RequestCell from './RequestCell';
import RequestUser from './RequestUser';
import RequestContent from './RequestContent';
import RequestOverlay from './RequestOverlay';
import { DateTime } from 'luxon';
import { DateLainer } from '@/js/vue/mixins/DateLainer';
import RequestStatusType from '@/js/enums/RequestStatusType';
import RequestStateType from '../../../js/enums/RequestStateType';

@Component({
    components: {
        RequestRow,
        RequestCell,
        RequestUser,
        RequestContent,
        RequestOverlay,
    },
})
class RequestTableRow extends Vue {
    @Prop({ type: Object, default: () => ({}) })
    request;

    isActive = false;

    get lastComment() {
        return last(this.request.comments).text;
    }

    get isObserver() {
        return this.request.observers.find((observer) => observer.id === this.$_account.id);
    }

    get statusStyle() {
        return {
            'background-color': colors[this.request.status],
        };
    }

    get statusTitle() {
        return titles[this.request.status];
    }

    get stateColor() {
        const type = RequestStateType.type(this.request);

        if (
            this.request.status === RequestStatusType.WORKUP &&
            (type === RequestStateType.WARN || type === RequestStateType.FATAL)
        ) {
            return {
                color: 'var(--color-white)',
                background: 'rgb(245, 34, 34)',
            };
        }

        return {};
    }

    terms(request) {
        const date = DateTime.fromSQL(request.deadline, { zone: DateLainer.SERVER_TIMEZONE });
        const now = DateTime.local().setZone(DateLainer.SERVER_TIMEZONE);

        if (date < now && request.status === RequestStatusType.WORKUP) {
            return 'Просрочено';
        } else if (!(request.status === RequestStatusType.WORKUP)) {
            return '';
        } else if (
            request.status === RequestStatusType.WORKUP &&
            date.diff(now) > 0 &&
            date.diff(now) <= 59 /* seconds*/ * 1000
        ) {
            return 'Скоро';
        } else {
            return this.$_toLocalRelativeDate(request.deadline);
        }
    }
}

export default RequestTableRow;
</script>
<style lang="scss">
.router-link-exact-active > .request__row {
    background-color: rgba(44, 44, 44, 0.12);
}

.router-link-exact-active[reqeust-card] > .request__row {
    background-color: rgba(44, 44, 44, 0.12);
}

.request {
    &__pill {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        padding: 2px 12px;
        border-radius: 10px;
        font-size: 0.75rem;
        color: var(--text-white);
        height: 22px;
    }

    &__content {
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 0.925rem;

        &_hidden {
            overflow: hidden;
        }

        &_center {
            align-items: center;
        }

        &_hidden-ellipsis {
            min-width: 1px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1 1 auto;
        }
    }

    &__overlay {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: 2;

        &_first-cell {
            margin-left: -10px;
        }

        &_last-cell {
            margin-right: -12px;
        }

        &_hover {
            &:hover {
                border: 1px solid #a8acae;
            }
        }

        &_without-left-border {
            &:hover {
                border-left: 0;
            }
        }

        &_without-right-border {
            &:hover {
                border-right: 0;
            }
        }
    }

    &__row {
        position: relative;
        padding: 0 10px;

        &_hover {
            &:hover {
                background-color: rgba(#000000, 0.12);
            }
        }
    }

    &__cell {
        position: relative;
        width: 100%;
        height: 32px;
        padding: 3px 10px;
        margin-right: -1px;
        margin-bottom: -1px;

        &_id {
            width: 10%;
        }

        &_executor {
            width: 20%;
        }

        &_status {
            width: 30%;
        }

        &_date {
            width: 20%;
        }

        &_author {
            width: 20%;
        }

        &_border {
            border: 1px solid #e8ecee;
        }

        &_without-left-border {
            border-left: 0;
        }

        &_without-right-border {
            border-right: 0;
        }
    }
}
</style>
