<template>
    <main class="admin-page__content">
        <ContentHeaderDefault title="Сервисы" button="Создать сервис" @search="onSearch" @button="onButtonClick">
        </ContentHeaderDefault>

        <template v-if="isLoading">
            <div class="admin-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <ContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onCityClick"
                @click="onCityClick"
                @delete="onDelete"
            ></ContentTableDefault>
        </template>

        <VAdminServiceCard></VAdminServiceCard>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VAdminServiceCard from '../../modals/VAdminServiceCard.vue';
import ContentTableDefault from './components/ContentTableDefault.vue';
import ContentHeaderDefault from './components/ContentHeaderDefault.vue';
import './AdminPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import { createService, updateService, deleteService, getAllServices } from '../../../js/admin-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VAdminServiceCard,
        ContentTableDefault,
        ContentHeaderDefault,
    },
})
class AdminPageServices extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 5,
                label: '№',
                display: (x) => x.id,
            },
            {
                size: 25,
                label: 'Категория',
                display: (x) => x.category.title,
            },
            {
                size: 25,
                label: 'Название',
                display: (x) => x.title,
            },
            {
                size: 25,
                label: 'Описание',
                display: (x) => x.subtitle,
            },
            {
                size: 20,
                label: 'Создан',
                display: (x) => this.$_toLocalDateHistory(x.createdAt),
            },
        ],
    };

    onSearch(searchString) {
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onDelete(service) {
        this.isLoading = true;

        try {
            await this.deleteService(service);

            const index = this.rows.indexOf(service);

            this.rows.splice(index, 1);
        } catch (error) {
            let message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    async onCityClick(service) {
        try {
            service = await this.$showModal(ModalNames.ADMIN_PANEL_SERVICE_CARD, {
                service,
            });

            this.isLoading = true;

            try {
                await this.updateService(service);
                await this.fetchServices();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.title) {
                    message = 'Сервис с таким именем уже существует';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onButtonClick() {
        try {
            const service = await this.$showModal(ModalNames.ADMIN_PANEL_SERVICE_CARD, {
                service: {
                    hours: 4,
                    title: '',
                    subtitle: '',
                    executorId: null,
                    securityGroupId: null,
                    observers: [],
                },
            });

            this.isLoading = true;

            try {
                await this.createService(service);
                await this.fetchServices();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.title) {
                    message = 'Категория с таким именем уже существует';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    createService(service) {
        return createService(service);
    }

    updateService(service) {
        return updateService(service);
    }

    deleteService(service) {
        return deleteService(service);
    }

    @Watch('page')
    @Watch('searchString')
    async fetchServices() {
        this.isLoading = true;

        try {
            const response = await getAllServices(this.page, this.searchString);

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.fetchServices();
    }
}

export default AdminPageServices;
</script>
