<template>
    <VPopover class="user-card__popover" popoverClass="popover-light" trigger="hover">
        <UserCard :status="status" :short-name="shortName" :picture-only="pictureOnly" :account="account"></UserCard>

        <template slot="popover">
            <UserCard class="user-card__popover-user prevent-close" :short-name="shortName" :account="account"></UserCard>

            <div class="user-card__popover-info prevent-close">
                <span class="user-card__popover-info-row">Почта: {{ account.email }}</span>
                <br />
                <span class="user-card__popover-info-row">Должность: {{ account.title }}</span>
                <br />
                <span class="user-card__popover-info-row">Компания: {{ account.company }}</span>
                <br />
                <span class="user-card__popover-info-row">Отдел: {{ account.department }}</span>
                <br />
                <span class="user-card__popover-info-row">Телефон: {{ account.telephone }}</span>
            </div>
        </template>
    </VPopover>
</template>

<script>
// Components
import UserCard from './UserCard.vue';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        UserCard,
    },
})
class UserCardWithPopover extends Vue {
    @Prop({ type: String, default: '' })
    status;

    @Prop({ type: Object, default: () => ({}) })
    account;

    @Prop({ type: Boolean, default: false })
    shortName;

    @Prop({ type: Boolean, default: false })
    pictureOnly;
}

export default UserCardWithPopover;
</script>

<style lang="scss">
.user-card {
    &__popover {
        display: flex;
    }

    &__popover-info {
        min-width: 320px;
        margin: 12px -16px -6px -16px;
        padding: 12px 0 0 0;
        border-top: 1px solid #e4eaef;
    }

    &__popover-info-row {
        display: inline-block;
        margin: 0 16px;
        padding: 6px 0;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #484848;
    }
}
</style>
