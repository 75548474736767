<template>
    <div class="request-create-store">
        <RouterLink
            to="/request/inbox"
            class="button button_no-shadow button_center button_without-padding button_padding-x request-create-store__button-back"
        >
            <ChevronLeftIcon class="button__title" :size="24" fill-color="#606060"> </ChevronLeftIcon>
            <span> Назад</span>
        </RouterLink>
        <BaseToolsSearch v-model="searchString" placeholder="Поиск" />
        <div class="categories" v-if="!isLoadingData" >
            <template>
                <span class="text-lg">Категория:</span>
                <transition-group name="animation-fadedown">
                    <template v-for="category in filteredCategories">
                        <RouterLink
                            :key="category.id"
                            :to="getServiceUrl(category)"
                            class="grid-flex grid-flex_column"
                        >
                        <ResourceItem :item="category"></ResourceItem>
                        </RouterLink>
                    </template>
                </transition-group>
            </template>
        </div>
        <VSpinner class="load" v-else></VSpinner>
    </div>
</template>
<script>
import Vue from 'vue';
import http from '../../../js/http';
import Component from 'vue-class-component';

import { ResourceItem, CategoryForm } from './components';
import { VActiveForm } from '../../components';

import VButton from '@/views/components/VButton/VButton.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import BaseToolsSearch from '@/views/components/tools/BaseToolsSearch';

import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft';

@Component({
    components: {
        VSpinner,
        VActiveForm,

        ChevronLeftIcon,

        ResourceItem,
        CategoryForm,

        VButton,
        BaseToolsSearch,
    },
})
class RequestCreateStore extends Vue {
    category = {};
    categories = [];
    searchString = '';
    isLoadingData = false;

    getServiceUrl(item) {
        const categoryId = item.id;

        return `/request/categories/${categoryId}/services`;
    }

    get filteredCategories() {
        if (this.searchString === '') return this.categories;

        const searchString = this.searchString.toLowerCase();

        return this.categories.filter(
            (category) =>
                category.title.toLowerCase().indexOf(searchString) !== -1 ||
                category.subtitle.toLowerCase().indexOf(searchString) !== -1,
        );
    }

    created() {
        this.isLoadingData = true;

        http.get('/categories').then(({ data: categories }) => {
            this.categories = categories;
            this.isLoadingData = false;
        });
    }
}

export default RequestCreateStore;
</script>
<style lang="scss">
.request-create-store{
    width: 100%;
    height: 100%;
    padding-right: 40px;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    &__button-back {
        height: 28px;
        line-height: 28px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0);
        justify-content: left;
        margin-bottom: 10px;
        &:hover {
            background-color: rgba(44, 44, 44, 0.12);
        }
    }
    .categories{
        margin: 10px 0;
        overflow-y: auto;
        height: 85%;
    }
    .load{
        position: relative;
        top: 30%;
        left: 50%;
    }
}
</style>
