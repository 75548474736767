import AdminMenu from '../views/pages/Admin/components/AdminMenu.vue';
import AdminPageUsers from '../views/pages/Admin/AdminPageUsers.vue';
import AdminPageCities from '../views/pages/Admin/AdminPageCities.vue';
import AdminPageServices from '../views/pages/Admin/AdminPageServices.vue';
import AdminPageCategories from '../views/pages/Admin/AdminPageCategories.vue';
import AdminPageSecurityGroups from '../views/pages/Admin/AdminPageSecurityGroups.vue';
import oidc from '../vue-oidc-client';

export default [
    {
        name: 'admin-panel',
        path: '/admin',
        components: {
            menu: AdminMenu,
            default: AdminPageUsers,
        },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        name: 'admin-panel.users',
        path: '/admin/users',
        components: {
            menu: AdminMenu,
            default: AdminPageUsers,
        },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        name: 'admin-panel.cities',
        path: '/admin/cities',
        components: {
            default: AdminPageCities,
            menu: AdminMenu,
        },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        name: 'admin-panel.services',
        path: '/admin/services',
        components: {
            default: AdminPageServices,
            menu: AdminMenu,
        },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        name: 'admin-panel.categories',
        path: '/admin/categories',
        components: {
            default: AdminPageCategories,
            menu: AdminMenu,
        },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        name: 'admin-panel.security-groups',
        path: '/admin/security-groups',
        components: {
            default: AdminPageSecurityGroups,
            menu: AdminMenu,
        },
        meta: {
            authName: oidc.authName,
        },
    },
];
