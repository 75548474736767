<template>
   <div>
        <div>
            <template v-for="(column, i) in renderColumns">
                <div :key="i" class="table-col_space-nowrap table-col_no-select" v-bind="getAttributes(column)">{{
                    column.title
                }}</div>
            </template>
        </div>

        <div :key="i" v-for="(renderValue, i) in renderValues">
            <template v-for="(column, k) in renderColumns">
                <div :key="k" v-bind="getAttributes(column)">{{ renderValue[column.name] }}</div>
            </template>
        </div>

        <div>
            <div v-bind="getAttributes(renderColumns[0])">Итого:</div>
            <template v-for="(column, k) in renderTotalColumns">
                <div :key="k" v-bind="getAttributes(column)">{{ renderResultValues[column.name] }}</div>
            </template>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

import _sumBy from 'lodash/sumBy';

class ExecutiveTableRender extends Vue {
    /** @type {Array} **/
    @Prop({ type: Array, default: () => [] }) renderColumns;
    /** @type {Object} **/
    @Prop({ type: Object, default: () => ({}) }) renderValues;
    /** @type {Array} **/
    @Prop({ type: Array, default: () => [] }) renderTotalColumns;

    getAttributes(column) {
        const name = `col-min-${column.preferredColumnSize}`;

        return { [name]: true };
    }

    get renderResultValues() {
        const renderTotalValues = Object.values(this.renderValues);

        return this.renderTotalColumns.reduce((carry, item) => {
            carry[item.name] = _sumBy(renderTotalValues, item.name);

            return carry;
        }, {});
    }
}

export default ExecutiveTableRender;
</script>
