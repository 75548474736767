<template>
    <div class="request-create-store">
            <RouterLink
                to="/request/categories"
                class="button button_no-shadow button_center button_without-padding button_padding-x request-create-store__button-back"
            >
                <ChevronLeftIcon class="button__title" :size="24" fill-color="#606060"> </ChevronLeftIcon>
                    Назад
            </RouterLink>
            <BaseToolsSearch v-model="searchString" placeholder="Поиск" />
            <div class="categories" v-if="!isLoadingData" >
                <template >
                    <span class="text-lg">Сервис:</span>
                    <transition-group name="animation-fadedown">
                        <template v-for="service in filteredServices">
                            <RouterLink :key="service.id" :to="getServiceUrl(service)" class="grid-col">
                                <ResourceItem
                                    :item="service"
                                    :default-subtitle="`Категория: ${service.category.title}`"
                                ></ResourceItem>
                            </RouterLink>
                        </template>
                    </transition-group>
                </template>
            </div>

            <VSpinner class="load" v-else></VSpinner>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { ResourceItem, ServiceForm } from './components';
import { VActiveForm } from '../../components';
import RequestCreateView from './RequestCreateView';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css'

import VButton from '@/views/components/VButton/VButton.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import BaseToolsSearch from '@/views/components/tools/BaseToolsSearch';

import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft';
import http from '../../../js/http';

@Component({
    components: {
        VSpinner,
        VActiveForm,

        ChevronLeftIcon,

        ResourceItem,
        ServiceForm,
        RequestCreateView,
        Splitpanes, Pane,

        VButton,
        BaseToolsSearch,
    },
})
class RequestCreateStore extends Vue {
    service = {};
    services = [];
    searchString = '';
    isLoadingData = false;

    getServiceUrl(item) {
        const serviceId = item.id;

        return `/request/categories/${this.categoryId}/services/${serviceId}`;
    }

    get categoryId() {
        return this.$route.params.categoryId;
    }

    get filteredServices() {
        if (this.searchString === '') return this.services;

        const searchString = this.searchString.toLowerCase();

        return this.services.filter((service) => service.title.toLowerCase().indexOf(searchString) !== -1);
    }

    created() {
        this.isLoadingData = true;

        http.get(`/categories/${this.categoryId}/services?with=observers,category`).then(({ data }) => {
            this.services = data;
            this.isLoadingData = false;
        });
    }
}

export default RequestCreateStore;
</script>
<style lang="scss">
.request-create-store{
    width: 100%;
    height: 100%;
    margin: 20px 20px 0 20px;
    &__button-back {
        height: 28px;
        line-height: 28px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0);
        justify-content: left;
        margin-bottom: 10px;
        &:hover {
            background-color: rgba(44, 44, 44, 0.12);
        }
    }
    .categories{
        margin: 10px 0;
        overflow-y: auto;
        height: 85%;
    }
    .load{
        position: relative;
        top: 30%;
        left: 50%;
    }
}
</style>
