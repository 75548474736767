import actions from './actions';
import mutations from './mutations';

const state = {
    accounts: [],
    services: [],
    categories: [],
    activeRequest: null,
    activeRequests: [],
    securityGroups: [],
    accessSecurityGroups: [],
    menuInfo: [],
};

export default {
    state,
    actions,
    mutations,
};
