export default {
    BOOT(state, app) {
        state.accounts = app.accounts;
        state.categories = app.categories ? app.categories : [];
        state.securityGroups = app.securityGroups ? app.securityGroups : [];
        state.accessSecurityGroups = app.accessSecurityGroups ? app.accessSecurityGroups : [];

        state.services = state.categories.reduce((result, category) => {
            result.push(...category.services);

            return result;
        }, []);
    },

    SET_REQUEST_STATUS(_, { request, status }) {
        request.status = status;
    },

    SET_ACTIVE_REQUEST(state, request) {
        state.activeRequest = request;
    },

    SET_REQUESTS_CONTEXT(state, requests) {
        state.activeRequests = requests;
    },

    UPDATE_REQUESTS_CONTEXT(state, requests) {
        requests.forEach((request) => {
            if (request.id === state.activeRequest?.id) {
                state.activeRequest = request;
            }

            const index = state.activeRequests.findIndex((item) => item.id === request.id);

            if (index !== -1) {
                state.activeRequests.splice(index, 1, request);
            } else {
                state.activeRequests.push(request);
            }
        });
    },

    ADD_REQUEST_OBSERVER(state, { request, observer }) {
        if (!Array.isArray(request.observers)) {
            request.observers = [];
        }

        request.observers.push(observer);
    },

    DELETE_REQUEST_OBSERVER(state, { request, observerId }) {
        const index = request.observers.findIndex((observer) => observer.id === observerId);

        if (index !== -1) {
            request.observers.splice(index, 1);
        }
    },

    UPDATE_REQUEST_IN_CONTEXT(state, request) {
        if (request.id === state.activeRequest?.id) {
            state.activeRequest = request;
        }

        const index = state.activeRequests.findIndex((item) => item.id === request.id);

        if (index !== -1) {
            state.activeRequests.splice(index, 1, request);
        }
    },

    UPDATE_MENU_INFO(state, info) {
        state.menuInfo = info;
    },
};
