var render = function render(){var _vm=this,_c=_vm._self._c;return _c('RequestRow',{class:['request__row_hover']},[_c('RequestContent',[_c('RequestCell',{class:['request__cell_id', 'request__cell_border', 'request__cell_without-left-border']},[_c('RequestContent',{class:['request__content_center', 'request__content_hidden-ellipsis']},[_vm._v(_vm._s(_vm.request.id)+" ")]),_c('RequestOverlay',{class:[
                    'request__overlay_hover',
                    'request__overlay_first-cell',
                    'request__overlay_without-left-border',
                ]})],1),_c('RequestCell',{class:['request__cell_author', 'request__cell_border'],attrs:{"title":_vm.request.author.displayName}},[_c('RequestContent',{class:['request__content_center', 'request__content_hidden-ellipsis']},[_c('RequestUser',{attrs:{"user":_vm.request.author,"reduce":""}})],1),_c('RequestOverlay',{class:['request__overlay_hover']})],1),_c('RequestCell',{class:['request__cell_executor', 'request__cell_border']},[_c('RequestContent',{class:['request__content_center', 'request__content_hidden-ellipsis']},[(_vm.request.executor)?_c('RequestUser',{attrs:{"user":_vm.request.executor,"reduce":""}}):_c('RequestUser',{attrs:{"name":(securityGroup) => securityGroup.name,"user":_vm.request.securityGroup}})],1),_c('RequestOverlay',{class:['request__overlay_hover']})],1),_c('RequestCell',{class:['request__cell_date', 'request__cell_border'],style:(_vm.stateColor),attrs:{"title":'до ' + _vm.$_toLocalDate(_vm.request.deadline)}},[_c('RequestContent',{class:['request__content_center', 'request__content_hidden-ellipsis']},[_vm._v(" "+_vm._s(_vm.terms(_vm.request))+" ")])],1),_c('RequestCell',{class:['request__cell_status', 'request__cell_border', 'request__cell_without-right-border']},[_c('RequestContent',{class:['request__content_hidden-ellipsis']},[_c('div',{staticClass:"request__pill request__pill_status",style:(_vm.statusStyle)},[_vm._v(" "+_vm._s(_vm.statusTitle)+" ")]),(_vm.isObserver)?_c('div',{staticClass:"request__pill request__pill_status",staticStyle:{"background-color":"#0db5b5"}},[_vm._v(" Наблюдатель ")]):_vm._e()]),_c('RequestOverlay',{class:[
                    'request__overlay_hover',
                    'request__overlay_last-cell',
                    'request__overlay_without-right-border',
                ]})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }