<template>
    <div>
        <template v-if="isLoadingRenderValue">
                <VSpinner></VSpinner>
        </template>

        <template v-else>
            <div class="actions">
                    <label>Период:</label>
                        <FlatPickr
                            id="date-period"
                            :config="datePeriodConfig"
                            class="input input_light"
                            placeholder="Период"
                            required
                            v-model="datePeriodValue"
                        ></FlatPickr>
                        <VButton @click="datePeriodSubmit" class="button_small button_green button_space-left"
                            >Применить</VButton
                        >
                    <div class="showing_settings" v-if="false" @click.stop.prevent>
                        <VButton @click="toggleRenderSettingsMenu" class="button_small button_light"
                            >Настройка отображения</VButton
                        >
                        <div v-if="showRenderSettingsMenu">
                            <FieldSettingsMenu :render-fields="renderColumns"></FieldSettingsMenu>
                        </div>
                    </div>
                    <div @click.stop.prevent>
                        <VButton @click="toggleFieldsSettingsMenu" class="button_small button_light button_space-left"
                            >Настройка полей</VButton
                        >
                        <div v-if="showFieldsSettingsMenu">
                            <FieldSettingsMenu :render-fields="renderColumns"></FieldSettingsMenu>
                        </div>
                    </div>
            </div>

            <div>
                <div class="grid-row_without-padding" v-for="(name, id) in renderGroups" :key="id">
                    <div>
                        <div>
                            <span class="text-xl">{{ name }}</span>
                        </div>

                        <ExecutiveTableRender
                            :render-values="renderValues[id]"
                            :render-columns="renderColumns"
                            :render-total-columns="userSettingsRenderColumns"
                        ></ExecutiveTableRender>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import http from '../../../js/http';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

import VButton from '../../components/VButton/VButton.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import { FieldSettingsMenu, ExecutiveTableRender } from './components';

@Component({
    components: {
        VButton,
        VSpinner,

        FieldSettingsMenu,
        ExecutiveTableRender,
    },
})
class ExecutiveTable extends Vue {
    isLoadingRenderValue = true;
    showFieldsSettingsMenu = false;
    showRenderSettingsMenu = false;

    datePeriodValue = '';
    datePeriodConfig = {
        mode: 'range',
        weekNumbers: true,
        dateFormat: 'd.m.Y',
        locale: Russian,
    };

    renderType = {};
    renderValues = [];
    renderGroups = [];
    renderColumns = [];

    settingsRenderTypes = [];
    settingsRenderColumns = [];

    userSettingsRenderType = {};
    userSettingsRenderColumns = [];

    toggleFieldsSettingsMenu() {
        this.hide();
        this.showFieldsSettingsMenu = !this.showFieldsSettingsMenu;
    }

    toggleRenderSettingsMenu() {
        this.hide();
        this.showRenderSettingsMenu = !this.showRenderSettingsMenu;
    }

    hide() {
        this.showFieldsSettingsMenu = false;
        this.showRenderSettingsMenu = false;
    }

    loadExecutiveTableRenderData() {
        this.isLoadingRenderValue = true;

        http.get(`/b/executive-table?period=${this.datePeriodValue}`).then(
            ({ data: { render, settings, userSettings } }) => {
                this.renderType = render.renderType;
                this.renderValues = render.renderValues;
                this.renderGroups = render.renderGroups;
                this.renderColumns = render.renderColumns;

                this.settingsRenderTypes = settings.renderTypes;
                this.settingsRenderColumns = settings.renderColumns;

                this.userSettingsRenderType = userSettings.renderType;
                this.userSettingsRenderColumns = userSettings.renderColumns;

                this.isLoadingRenderValue = false;
            },
        );
    }

    datePeriodSubmit() {
        this.updateCurrentPerion();
        this.loadExecutiveTableRenderData();
    }

    updateCurrentPerion() {
        this.datePeriodValue = this.datePeriodValue ? this.datePeriodValue : this.$route.query.period;

        if (!this.datePeriodValue || this.datePeriodValue.length === 0) {
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            this.datePeriodValue = '{0}.{1}.{2} — {3}.{4}.{5}'.formatUnicorn(
                firstDay.getDate(),
                firstDay.getMonth() + 1,
                firstDay.getFullYear(),
                lastDay.getDate(),
                lastDay.getMonth() + 1,
                lastDay.getFullYear(),
            );
        }

        this.$router.replace({
            name: 'executive-table',
            query: {
                period: this.datePeriodValue,
            },
        });
    }

    created() {
        this.updateCurrentPerion();
        this.loadExecutiveTableRenderData();

        document.addEventListener('click', this.hide);
    }

    beforeDestroy() {
        document.removeEventListener('click', this.hide);
    }
}

export default ExecutiveTable;
</script>
<style lang="scss">
.executive-table {
    &__filed-setting-menu {
        top: 30px;
        right: 0;
        z-index: 1010;
    }
}
</style>
